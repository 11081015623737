// src/InProgress.tsx
import React from "react";
import "./InProgress.css";

const InProgress: React.FC = () => {
  return (
    <div className="in-progress">
      <h1>🚧 Website Under Construction 🚧</h1>
      <p>
        We’re working hard to bring you something awesome. Please check back
        soon!
      </p>
    </div>
  );
};

export default InProgress;
